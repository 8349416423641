import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class PhilosophyPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const image_url = data.contentfulAsset.file.url;

    return (
      <Layout location="./philosophy" title={siteTitle}>
        <SEO title="Philosophy" keywords={[`painting`, `historical`]} />
        <div className="left">
          <p>
            To be a leader in the paint and finishing industry whether it is a
            new or green product or using an old world technique to capture the
            proper aesthetic. Realizing that our success depends on meeting our
            clients high expectations, I promise my clients that we will use the
            highest quality products, are on schedule and be equitably priced. I
            will make personalized service and an open line of communication my
            top priority. To my craftsmen I pledge that their work be meaningful
            and that they are compensated fairly, and as a team our ultimate
            goal is great work and a satisfied client.
          </p>
        </div>
        <div className="right">
          <img src={image_url} alt="A beautiful dresser" />
        </div>
      </Layout>
    );
  }
}

export default PhilosophyPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAsset(title: { eq: "philosophy-content" }) {
      file {
        url
      }
    }
  }
`;
